import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 60px;

  ${up('lg')} {
    padding-top: 160px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 27px;
  max-width: 500px;
  color: ${colors.white};
  margin: 24px 0px;
  align-items: center;
  text-align: center;
  justify-content: center;

  ${up('lg')} {
    margin: 42px 0px;
    align-items: center;
  }
`

export const Header = styled.h1`
  margin: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 120%;
  text-align: center;

  ${up('lg')} {
    font-size: 55px;
  }
`

export const SubHeader = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: center;

  ${up('lg')} {
    max-width: 680px;
    margin-top: 42px;
  }
`

export const Icon = styled.img`
  width: 50px;
  height: 50px;

  ${up('lg')} {
    margin-top: 14px;
  }
`

export const HubspotStyleWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 850px;
  max-height: 642px;
  width: 90%;
  .meetings-iframe-container {
    height: 100%;
    width: 100%;
  }

  ${up('lg')} {
    width: 100%;
  }
`
