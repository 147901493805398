//TODO: lift these to common components
import { Header as DefaultHeader } from 'pages/Home/styled-components'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
`

export const Header = styled(DefaultHeader)`
  font-size: 30px;
  text-align: center;
  margin: 0;
  margin-top: 82px;

  ${up('lg')} {
    font-size: 60px;
  }
`

export const SubHeader = styled.h2`
  color: ${colors.white};
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  max-width: 370px;
  margin-bottom: 80px;

  ${up('lg')} {
    max-width: 600px;
    font-size: 20px;
  }
`

export const ServicesContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 50px;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 82px;

  ${up('lg')} {
    margin-top: 100px;
    flex-direction: row;
  }
`

export const ServiceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ServiceImage = styled.img`
  z-index: 2;
  height: auto;
  max-height: 100px;
  max-width: 150px;
  width: calc(50% - 50px);

  ${up('lg')} {
    width: calc(30% - 50px);
  }
`
