import ReactGA from 'react-ga4'
import { Toaster } from 'react-hot-toast'
import { QueryClientProvider } from 'react-query'
import {
  Route,
  Routes,
  BrowserRouter,
  Outlet as ActiveRouteComponent,
} from 'react-router-dom'

import { WixProvider, OAuthStrategy } from '@wix/sdk-react'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { avatars } from 'constants/avatars'
import { H } from 'highlight.run'
import About from 'pages/About'
import Blog from 'pages/Blog'
import Done from 'pages/Done'
import Error from 'pages/Error'
import Home from 'pages/Home'
import Maintenance from 'pages/Maintenance'
import NewsLetter from 'pages/NewsLetter'
import Questions from 'pages/Questions'
import ResourceHub from 'pages/ResourceHub'
import Services from 'pages/Services'
import QueryClient from 'services/QueryClient'
import { ThemeProvider } from 'styled-components'
import { Theme } from 'styles'

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID
const HIGHLIGHTS_ID = process.env.REACT_APP_HIGHLIGHT_PROJECT_ID
const CLIENT_ID = process.env.REACT_APP_WIX_CLIENT_ID as string

if (TRACKING_ID) ReactGA.initialize(TRACKING_ID)
if (HIGHLIGHTS_ID) {
  H.init(HIGHLIGHTS_ID, {
    serviceName: 'ryco.io - web app',
    tracingOrigins: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
    },
  })
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            //global views
            <QueryClientProvider client={QueryClient}>
              <WixProvider
                auth={OAuthStrategy({
                  clientId: CLIENT_ID,
                })}
              >
                <ThemeProvider theme={Theme}>
                  <Toaster />
                  <Header />
                  <ActiveRouteComponent />
                  <Footer />
                </ThemeProvider>
              </WixProvider>
            </QueryClientProvider>
          }
        >
          <Route index element={<Home />} />
          {avatars.map((avatar) => (
            <Route key={avatar.to} path={avatar.to} element={<Questions />} />
          ))}
          <Route path="/done/*" element={<Done />} />
          <Route path="/about" element={<About />} />
          <Route path="/newsletter" element={<NewsLetter />} />
          <Route path="/resourceHub" element={<ResourceHub />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route
            path="/terms"
            element={
              <iframe
                src="https://drive.google.com/file/d/1udGWAKyE8TbrOy6JmEDQxKmFPmpE7PH6/preview"
                title="Terms of Service"
                style={{ width: '100%', height: '100vh' }}
              />
            }
          />
          <Route
            path="/privacy"
            element={
              <iframe
                src="https://drive.google.com/file/d/1WGvZVAvRJ3O-gReI9UiUlxXo0iqjgrvx/preview"
                title="Privacy Policy"
                style={{ width: '100%', height: '100vh' }}
              />
            }
          />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
