import React from 'react'

import educatorEssential from 'assets/images/sassTable/educatorEssentials.png'
import enterpriseElite from 'assets/images/sassTable/enterpriseElite.png'
// import instutionPlus from 'assets/images/sassTable/instutionPlus.png'
import teacherToolkit from 'assets/images/sassTable/teacherToolkit.png'
import Blob from 'components/BackgroundOverlay/Blob'
import { Circle } from 'components/BackgroundOverlay/styled-components'
import { useBreakpoints } from 'styles'
import { colors } from 'styles'

import { CustomBr } from '../ValueProp/ValueProp'
import {
  Container,
  Header,
  SubHeader,
  SassTableContainer,
  SassTableHeader,
  Link,
  TableWrapper,
  SassTitle,
  SassPrice,
  SassLabel,
  SassDescription,
  SassItem,
  BlankSassItem,
  BlankWrapper,
  FeaturesWrapper,
  LinkButton,
} from './styled-components'

const CircleConfigurations = [
  {
    color: colors.burntOrange,
    size: [142, 242],
    left: [-56, -100],
    top: [42, 100],
  },
  {
    color: colors.rycoPurple,
    size: [200, 242],
    right: [100, 100],
    top: [-85, -85],
  },
  {
    color: colors.yellow,
    size: [120, 160],
    right: [-81, -11],
    bottom: [190, 190],
  },
  {
    color: colors.yellow,
    size: [32, 52],
    right: [42, 142],
    bottom: [350, 350],
  },
  {
    color: colors.lightGreen,
    size: [160, 160],
    right: [0, 328],
    bottom: [-24, -24],
  },

  {
    color: colors.blue,
    size: [120, 160],
    left: [40, 40],
    bottom: [60, 120],
  },
]

interface SassTableItemProps {
  item: {
    color: string
    image: string
    title: string
    price: string
    label: string
    description: string
    includes: string[]
    blank?: number
    cta: {
      text: string
      link: string
    }
  }
}

const SassTableItem: React.FC<SassTableItemProps> = ({ item }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '250px',
        maxWidth: '300px',
      }}
    >
      <img style={{ width: '100%' }} src={item.image} alt={item.title} />
      <div
        style={{
          backgroundColor: item.color,
          borderTopRightRadius: '5px',
          borderTopLeftRadius: '5px',
          color: colors.white,
          padding: '30px',
        }}
      >
        <SassTitle>{item.title}</SassTitle>
        <SassPrice>{item.price}</SassPrice>
        <SassLabel>{item.label}</SassLabel>
        <SassDescription>{item.description}</SassDescription>
      </div>
      <FeaturesWrapper
        color={item.color}
        isCustom={item.title === 'Enterprise Elite'}
      >
        <span
          style={{
            fontSize: 18,
            fontWeight: 600,
            marginBottom: 20,
          }}
        >
          Includes:
        </span>
        <ul
          style={{
            padding: 0,
            paddingLeft: 25,
            margin: 0,
          }}
        >
          {item.includes.map((include: string, index: number) => (
            <SassItem key={index}>
              <span
                style={{
                  top: -8,
                  position: 'relative',
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                {include}
              </span>
            </SassItem>
          ))}
        </ul>
        {item?.blank && (
          <BlankWrapper>
            {Array.from({ length: item?.blank }).map((_, index) => (
              <BlankSassItem>-</BlankSassItem>
            ))}
          </BlankWrapper>
        )}
        <LinkButton href={item.cta.link}>{item.cta.text}</LinkButton>
      </FeaturesWrapper>
    </div>
  )
}

const SassConvert = () => {
  const { isTabletOrBelow, isMobile } = useBreakpoints()

  const tiers = [
    {
      color: colors.rycoPurple,
      image: teacherToolkit,
      title: 'Teacher Toolkit',
      price: '$14.99 per month',
      label: 'Personal account',
      description:
        'For educators ready to dive deeper into AI solutions to save time and create personalized content for their classrooms.',
      includes: [
        'Asset Request Dashboard',
        'Helpful tips and best practices (tips section)',
        '100-200 rybot prompts per month (depending on complexity)',
        // 'Quarterly ryverse events',
      ],
      // blank: 9,
      blank: isMobile ? undefined : 5,
      cta: {
        text: 'Create an account',
        link: 'https://www.app.ryco.io/auth/createAccount',
      },
    },
    {
      color: colors.darkPurple,
      image: educatorEssential,
      title: 'Educator Essentials',
      price: '$34.99 per user/mo',
      label: '$349.99 per user/yr',
      description:
        'A team of educators looking for more robust classroom resources, increased AI usage, and a dedicated ryco manager.',
      includes: [
        'Asset Request Dashboard',
        'Helpful tips and best practices (tips section)',
        '250-500 rybot prompts per month (depending on complexity)',
        // 'Quarterly ryverse events',
        'Access to rybot document upload',
        'Access to ryverse for team collaboration',
        'One dedicated Project Manager',
        'Scheduling link for on-demand support',
        'Flexible payment options',
        // 'Basic admin controls',
      ],
      // blank: 3,
      cta: {
        text: 'Create an account',
        link: 'https://www.app.ryco.io/auth/createAccount',
      },
    },
    // {
    //   color: colors.purple,
    //   image: instutionPlus,
    //   title: 'Institution Plus+',
    //   price: '$74.99 per user/mo',
    //   label: '$749.99 per user/yr',
    //   description:
    //     'School leaders, administrators, and department heads managing large-scale operations and requiring full oversight of resources and teams.',
    //   includes: [
    //     'Asset Request Dashboard',
    //     'Helpful tips and best practices (tips section)',
    //     '500-1000 rybot prompts per month (depending on complexity)',
    //     'Quarterly ryverse events',
    //     "Access to all rybot's features",
    //     'Access to ryverse for team collaboration',
    //     'One dedicated Project Manager',
    //     'Scheduling link for on-demand support',
    //     'Flexible payment options',
    //     'Remove & add users to your organization',
    //     'View and approve team asset requests and cost estimates',
    //     'Budget tracker',
    //     'Admin level team controls',
    //   ],
    // },
    {
      color: colors.grayDark1,
      image: enterpriseElite,
      title: 'Enterprise Elite',
      price: 'Custom pricing',
      label: 'Get in touch',
      description:
        'Large institutions, educational organizations, and companies requiring a fully customized AI solution for teaching, collaboration and resource management.',
      includes: [
        'All available features',
        'Increased AI Teaching Assistant (rybot) custom number of requests',
        'Company-specific virtual campus with access to ryverse for dedicated support',
      ],
      cta: {
        text: 'Schedule a call',
        link: 'https://meetings.hubspot.com/hannah-williams4?uuid=8a03b28a-b534-41c6-807c-8faed0b4df2a',
      },
    },
  ]

  return (
    <>
      <Container>
        <Header>
          Are you ready to <CustomBr /> try ryco?
        </Header>
        <SubHeader>
          Our tiered pricing structure provides flexible options for various
          budgets, from individual <CustomBr />
          teacher plans to department-wide plans for larger schools and
          districts.
        </SubHeader>
        {CircleConfigurations.map((config, index) => (
          <Circle
            size={isTabletOrBelow ? config.size[0] : config.size[1]}
            key={index}
            right={isTabletOrBelow ? config.right?.[0] : config.right?.[1]}
            top={isTabletOrBelow ? config.top?.[0] : config.top?.[1]}
            left={isTabletOrBelow ? config.left?.[0] : config.left?.[1]}
            bottom={isTabletOrBelow ? config.bottom?.[0] : config.bottom?.[1]}
            isTabletOrBelow={isTabletOrBelow}
            color={isTabletOrBelow ? config.color : 'transparent'}
          >
            {!isTabletOrBelow && (
              <Blob
                color={config.color}
                radius={isTabletOrBelow ? config.size[0] : config.size[1] / 2}
                markers={false}
                height={isTabletOrBelow ? config.size[0] : config.size[1] + 100}
              />
            )}
          </Circle>
        ))}
      </Container>
      <SassTableContainer>
        <SassTableHeader>Choose the plan that's right for you</SassTableHeader>
        <Link href="https://www.app.ryco.io/auth/createAccount">
          or continue for free
        </Link>
        <TableWrapper>
          {tiers.map((tier, index) => (
            <SassTableItem key={index} item={tier} />
          ))}
        </TableWrapper>
      </SassTableContainer>
    </>
  )
}

export default SassConvert

//test on mobile, add CTA buttons
