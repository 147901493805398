import React from 'react'

import ScrollButton from 'components/ScrollToTopButton'
import { CustomHeader, CustomSubHeader } from 'pages/Blog/Blog'
import SassConvert from 'pages/Home/SassConvert'
import { useBreakpoints } from 'styles'

import firstExample from './1.png'
import secondExample from './2.png'
import thirdExample from './3.png'
import fourthExample from './4.png'
import fifthExample from './5.png'
import sixthExample from './6.png'
import eighthExample from './8.png'
import ninthExample from './9.png'
import {
  Wrapper,
  CustomListItemLabel,
  CustomListItem,
  CustomItem,
  Container,
  CustomDiv,
  CustomExample,
  CustomWrapper,
  SampleModalButton,
  CustomImage,
  ModalWrapper,
  ModalContent,
  ModalTitle,
} from './styled-components'

const Services = () => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const [modalContent, setModalContent] = React.useState<
    React.ReactNode | undefined
  >(undefined)
  const [modalTitle, setModalTitle] = React.useState<string>('')

  const { isMobile, isTabletOrBelow } = useBreakpoints()

  const CustomModal = () => {
    //capture esc key to close modal

    React.useEffect(() => {
      const handleEsc = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setModalOpen(false)
          setModalContent(undefined)
          setModalTitle('')
        }
      }

      window.addEventListener('keydown', handleEsc)

      return () => {
        window.removeEventListener('keydown', handleEsc)
      }
    }, [])

    return (
      <ModalWrapper>
        <ModalContent>
          <ModalTitle>
            {modalTitle}

            <button
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => {
                setModalOpen(false)
                setModalContent(undefined)
                setModalTitle('')
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 2.41714L21.5829 0L12 9.58286L2.41714 0L0 2.41714L9.58286 12L0 21.5829L2.41714 24L12 14.4171L21.5829 24L24 21.5829L14.4171 12L24 2.41714Z"
                  fill="#888888"
                />
              </svg>
            </button>
          </ModalTitle>

          {modalContent}
        </ModalContent>
      </ModalWrapper>
    )
  }

  const data = [
    {
      label: 'Custom Interactive Videos and Animations',
      text: ' Engaging content that brings complex subjects to life, with options for interactivity to support active learning.',
      example: firstExample,
      fullVersion: (
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: '100%',
            width: '100%',
          }}
        >
          <iframe
            title="Animated Video Example"
            src="https://drive.google.com/file/d/15tk5peo9VW_ReaoRQCeLnvK-UYS_VYfm/preview"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          ></iframe>
        </div>
      ),
    },
    {
      label: 'Virtual Campuses for Schools',
      text: 'Bring remote learning to life with dynamic custom environments that foster peer-to-peer collaboration, engagement, and a sense of community for students and educators.',
      example: secondExample,
      fullVersion: (
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: '100%',
            width: '100%',
          }}
        >
          <iframe
            title="ryverse walkthrough"
            src="https://drive.google.com/file/d/1qNOTNpZasmgFkbmbHjIR0L85DnMYKjlw/preview"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          ></iframe>
        </div>
      ),
    },
    {
      label: 'Gamified Learning Modules',
      text: ' Interactive games and activities that reinforce key concepts in a fun, engaging way.',
      example: thirdExample,
      fullVersion: (
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: '100%',
            width: '100%',
          }}
        >
          <iframe
            title="fossil dig game"
            src="https://website-fossil-example.onrender.com"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          ></iframe>
        </div>
      ),
    },
    {
      label: 'Immersive Stories',
      text: 'Bring books to life with immersive storytelling that increases engagement and comprehension.',
      example: fourthExample,
      fullVersion: (
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: '100%',
            width: '100%',
          }}
        >
          <iframe
            title="Interactive Children's Book"
            src="https://children-book-prototype.web.app/"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          ></iframe>
        </div>
      ),
    },
    {
      label: 'XR Experiences',
      text: ' Virtual tours, environment exploration, and VR-ready assets that give students immersive learning opportunities from anywhere.',
      example: fifthExample,
      fullVersion: (
        <iframe
          src="https://explore.ludenso.com/experiences/efbae5ac18e54c39550b08dd183a6cb5/enrichments/359f8f4917e74cce94bf257ed91be61c/embed"
          title="Blue whale"
          style={{
            background: 'none transparent',
            border: 'none',
            width: '100%',
            height: isMobile ? '300px' : '500px',
          }}
          allowFullScreen
          allowTransparency
        />
      ),
    },
    {
      label: 'Interactive Timelines and Scenarios',
      text: ' Content that lets students navigate historical events or make decisions in scenarios, encouraging critical thinking and active engagement.',
      example: sixthExample,
      fullVersion: (
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: '100%',
            width: '100%',
          }}
        >
          <iframe
            title="cinderella spanish example"
            src="https://website-cinderella-example.onrender.com/"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          ></iframe>
        </div>
      ),
    },
    {
      label: 'Supplemental Course Materials and Teacher Resources',
      text: ' This includes anything from ready-to-use lesson plans, study guides, glossaries, and decodable texts that support accessibility and comprehension.',
      example: eighthExample,
      fullVersion: isMobile ? (
        <iframe
          title="supplemental course materials"
          src="https://drive.google.com/file/d/1wejeO36pwGpzUP2sn1Q_zds62Dv8QIdc/preview"
          style={{
            width: '100%',
            height: '75svh',
            background: 'none transparent',
            border: 'none',
          }}
          loading="eager"
          allowFullScreen
          allowTransparency
        />
      ) : (
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: '100%',
            width: '100%',
          }}
        >
          <iframe
            title="supplemental course materials"
            src="https://drive.google.com/file/d/1wejeO36pwGpzUP2sn1Q_zds62Dv8QIdc/preview"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          ></iframe>
        </div>
      ),
    },
    {
      label: 'Translation and Accessibility Tools',
      text: ' Educators can ensure all materials are accessible for students from diverse linguistic backgrounds using voiceover and translation services from over 120 languages.',
      example: ninthExample,
      fullVersion: (
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <iframe
            title="Animal World Vocab Game"
            src="https://website-3d-animal-example.onrender.com"
            allowFullScreen
            allowTransparency
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 'none',
              background: 'none transparent',
            }}
          ></iframe>
        </div>
      ),
    },
  ]

  return (
    <div>
      <Container>
        <Wrapper>
          <CustomHeader style={{ whiteSpace: 'nowrap' }}>
            Our Services
          </CustomHeader>
          <CustomSubHeader>
            ryco.io offers a comprehensive, one-stop hub designed to improve the
            content development process, and enhance the teaching experience.
            Our platform features rybot, an AI-powered teaching assistant that
            streamlines classroom preparation by creating lesson plans,
            translating materials into over 120 languages, and providing
            tailored support for diverse learning needs. Beyond rybot, ryco.io
            enables educators to request fully customized educational assets
            tailored to their unique classroom requirements, including:
          </CustomSubHeader>
          <CustomDiv>
            {data.map((item, index) => (
              <CustomWrapper key={index}>
                <CustomItem key={index}>
                  <CustomListItemLabel>{item.label}</CustomListItemLabel>
                  <CustomListItem>{item.text}</CustomListItem>
                  {item?.fullVersion && (
                    <SampleModalButton
                      onClick={() => {
                        setModalOpen(true)
                        setModalContent(item?.fullVersion)
                        setModalTitle(item.label)
                      }}
                    >
                      View Sample
                    </SampleModalButton>
                  )}
                </CustomItem>
                <CustomExample>
                  <CustomImage src={item.example} alt="example" />
                </CustomExample>
              </CustomWrapper>
            ))}
          </CustomDiv>
        </Wrapper>
      </Container>

      {/* a modal that opens when the sample modal button is clicked with the full sample of that item insside of it   */}
      {modalOpen && <CustomModal />}

      <SassConvert />
      <ScrollButton />
    </div>
  )
}

export default Services
