import React, { useEffect, useRef } from 'react'

import { colors as colorsObj } from 'styles'

// Filter out colors with "purple" in the key name
const colors = Object.entries(colorsObj)
  .filter(([key]) => !key.toLowerCase().includes('purple')) // Exclude keys with "purple"
  .map(([, value]) => value) // Extract color values

interface BackgroundProps {
  textBox: { x: number; y: number; width: number; height: number }
}

const Background: React.FC<BackgroundProps> = ({ textBox }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  useEffect(() => {
    const canvas = canvasRef.current!
    const ctx = canvas.getContext('2d')!
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    })

    class Bubble {
      x: number
      y: number
      radius: number
      color: string
      speedX: number
      speedY: number

      constructor() {
        this.radius = Math.random() * 20 + 15 // ✅ Assign radius before using it

        // Ensure the bubble spawns outside the text box
        do {
          this.x = Math.random() * canvas.width
          this.y = Math.random() * canvas.height
        } while (
          this.x + this.radius > textBox.x &&
          this.x - this.radius < textBox.x + textBox.width &&
          this.y + this.radius > textBox.y &&
          this.y - this.radius < textBox.y + textBox.height
        )

        this.color = colors[Math.floor(Math.random() * colors.length)]
        this.speedX = (Math.random() - 0.5) * 2
        this.speedY = (Math.random() - 0.5) * 2
      }

      update() {
        this.x += this.speedX
        this.y += this.speedY

        // Bounce off screen edges
        if (this.x - this.radius < 0 || this.x + this.radius > canvas.width)
          this.speedX *= -1
        if (this.y - this.radius < 0 || this.y + this.radius > canvas.height)
          this.speedY *= -1

        // **Bounce off text area**
        if (
          this.x + this.radius > textBox.x &&
          this.x - this.radius < textBox.x + textBox.width &&
          this.y + this.radius > textBox.y &&
          this.y - this.radius < textBox.y + textBox.height
        ) {
          // Bounce away from text
          if (this.x < textBox.x || this.x > textBox.x + textBox.width)
            this.speedX *= -1
          if (this.y < textBox.y || this.y > textBox.y + textBox.height)
            this.speedY *= -1
        }
      }

      draw() {
        ctx.beginPath()
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2)
        ctx.fillStyle = this.color
        ctx.globalAlpha = 0.7
        ctx.fill()
        ctx.globalAlpha = 1
      }
    }

    // Create bubbles
    const bubbles: Bubble[] = []
    for (let i = 0; i < 50; i++) {
      bubbles.push(new Bubble())
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      bubbles.forEach((bubble) => {
        bubble.update()
        bubble.draw()
      })

      requestAnimationFrame(animate)
    }

    animate()
  }, [textBox]) // Recalculate when textBox changes

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: -1,
      }}
    />
  )
}

export default Background
