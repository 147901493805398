//TODO: lift common components
import { Circle } from 'components/BackgroundOverlay/styled-components'
// import { SubHeader as DefaultSubHeader } from 'pages/Home/Customers/styled-components'
// import { Header as DefaultHeader } from 'pages/Home/styled-components'
import { CustomHeader, CustomSubHeader } from 'pages/Blog/Blog'
import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'

export const animationTiming = 1

export const CustomCircle = styled(Circle)<{
  animationFinished: boolean
  angle?: number
}>`
  ${({ animationFinished, angle = 0 }) =>
    animationFinished &&
    css`
      animation: ${'growAndJiggle' + String(angle).split('.')[0]}
        ${animationTiming}s;

      @keyframes ${'growAndJiggle' + String(angle).split('.')[0]} {
        0%,
        100% {
          transform: scale(1) rotate(${angle}deg);
        }
        50% {
          transform: scale(1.1) rotate(${angle}deg);
        }
      }
    `}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: fit-content;
  justify-content: center;
  align-items: center;
`

export const Header = styled(CustomHeader)`
  text-align: center;
  margin: 0;
  align-self: center;
  z-index: 1;
  max-width: 370px;
  margin-top: 95px;
  color: ${colors.white};
  font-size: 28px;
  ${up('lg')} {
    font-size: 50px;
    max-width: 100%;
    white-space: nowrap;
    margin-top: 115px;
  }
`

export const SubHeader = styled(CustomSubHeader)`
  text-align: center;
  margin: 0;
  z-index: 1;
  max-width: 370px;
  font-size: 16px;
  margin-top: 38px;
  align-self: center;
  color: ${colors.white};
  font-weight: 600;

  ${up('lg')} {
    max-width: 850px;
    font-size: 20px;
    margin-top: 52px;
    font-weight: 500;
  }
`

//TODO: combine with button in valueprop styled-compontents and lift to commmon with variants
export const Button = styled.a.attrs({
  target: '_blank',
})`
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  color: ${colors.white};
  background: radial-gradient(
      83.94% 83.94% at 26.39% 20.83%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #000;
  border: none;
  border-radius: 109px;
  width: 75%;
  height: 56px;
  text-decoration: none;
  margin-top: 261px;

  ${up('lg')} {
    height: 60px;
    font-size: 18px;
    padding: 0 30px;
    width: fit-content;
    margin-top: 585px;
  }
`

export const Spacer = styled.div`
  height: 56px;
  margin-top: 261px;

  ${up('lg')} {
    height: 60px;
    margin-top: 585px;
  }
`

const Image = styled.img`
  position: absolute;
  width: 205px;
  top: 450px;

  ${up('lg')} {
    width: 533px;
    top: 516px;
  }
`

export const RightFist = styled(Image)`
  left: 50%;

  &.animateIn {
    animation: slideInRightFist ${animationTiming}s linear;
  }

  &.animateOut {
    animation: slideOutRightFist ${animationTiming}s linear;
    left: 100%;
  }

  @keyframes slideOutRightFist {
    0% {
      left: 50%;
    }
    100% {
      left: 100%;
    }
  }

  @keyframes slideInRightFist {
    0% {
      left: 100%;
    }
    100% {
      left: 50%;
    }
  }
`

export const LeftFist = styled(Image)`
  right: 50%;

  &.animateIn {
    animation: slideInLeftFist ${animationTiming}s linear;
  }

  &.animateOut {
    animation: slideOutLeftFist ${animationTiming}s linear;
    right: 100%;
  }

  @keyframes slideOutLeftFist {
    0% {
      right: 50%;
    }
    100% {
      right: 100%;
    }
  }

  @keyframes slideInLeftFist {
    0% {
      right: 100%;
    }
    100% {
      right: 50%;
    }
  }
`
