import * as React from 'react'

import { Header, SubHeader } from '../Home/styled-components'
import AIOrb from './AIOrb'
import Background from './Background'

const Maintenance = () => {
  const textRef = React.useRef<HTMLDivElement | null>(null)
  const [textBox, setTextBox] = React.useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })

  React.useEffect(() => {
    const updateTextBox = () => {
      if (textRef.current) {
        const rect = textRef.current.getBoundingClientRect()
        setTextBox({
          x: rect.left,
          y: rect.top,
          width: rect.width,
          height: rect.height,
        })
      }
    }

    updateTextBox()
    window.addEventListener('resize', updateTextBox)
    return () => window.removeEventListener('resize', updateTextBox)
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100svh',
        width: '100svw',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <AIOrb />
      <Background textBox={textBox} /> {/* Pass the dynamic textBox size */}
      <div ref={textRef} style={{ position: 'relative', zIndex: 10 }}>
        <Header style={{ margin: 0, padding: '0 20px', marginBottom: 24 }}>
          Pardon Our Progress
        </Header>
        <SubHeader style={{ margin: 0, maxWidth: 800, padding: '0 20px' }}>
          We're making improvements to our platform to provide you with
          cutting-edge, personalized learning solutions. Our team will have
          everything up and running again shortly.
        </SubHeader>
      </div>
    </div>
  )
}

export default Maintenance
