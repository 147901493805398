import React from 'react'

import val1 from 'assets/images/valueProps/val1.png'
import val2 from 'assets/images/valueProps/val2.png'
import val3Mobile from 'assets/images/valueProps/val3-mobile.png'
import val3 from 'assets/images/valueProps/val3.png'
import Blob from 'components/BackgroundOverlay/Blob'
import { Circle } from 'components/BackgroundOverlay/styled-components'
import { TabletAndBelow, useBreakpoints } from 'styles'
import { colors } from 'styles'
import { Desktop } from 'styles'

import {
  Header,
  Container,
  Image,
  Description,
  ValPropWrapper,
  RybotImageWrapper,
} from './styled-components'

const config = {
  color: colors.lightGreen,
  size: [250, 500],
  right: [-125, -250],
  top: [-100, -175],
}

export const CustomBr = () => (
  <Desktop>
    <br />
  </Desktop>
)

const ValueProp = () => {
  const valProps = [
    {
      img: val1,
      text: 'rybot animated robot',
    },
    {
      img: val2,
      text: 'cup with pencil, and ruller with differnt file types around the cup on an orange background',
    },
    {
      img: val3,
      mobile: val3Mobile,
      text: 'two cartoon hands high fiving, over a yellow background, with a map of ryverse in the background',
    },
  ]
  const { isTabletOrBelow } = useBreakpoints()

  return (
    <Container>
      <Circle
        size={isTabletOrBelow ? config.size[0] : config.size[1]}
        right={isTabletOrBelow ? config.right?.[0] : config.right?.[1]}
        top={isTabletOrBelow ? config.top?.[0] : config.top?.[1]}
        isTabletOrBelow={isTabletOrBelow}
        color={isTabletOrBelow ? config.color : 'transparent'}
      >
        {!isTabletOrBelow && (
          <Blob
            color={config.color}
            radius={isTabletOrBelow ? config.size[0] : config.size[1] / 2}
            markers={false}
            height={isTabletOrBelow ? config.size[0] : config.size[1] + 100}
          />
        )}
      </Circle>
      <ValPropWrapper
        marginTop="100px"
        marginBottom="50px"
        flexDirectionMobile="column-reverse"
      >
        <div>
          <Header>
            Revolutionize
            <CustomBr /> Teaching with rybot: <CustomBr /> Your All-in-One AI{' '}
            <CustomBr /> Assistant
          </Header>
          <Description>
            A comprehensive hub featuring rybot, an AI-powered <CustomBr />
            teaching assistant, simplifying lesson planning,
            <CustomBr /> translating materials into 120+ languages, and offering
            <CustomBr /> tailored support to enhance classroom preparation for
            <CustomBr /> diverse learning needs.
          </Description>
        </div>
        <RybotImageWrapper>
          <Image
            style={{ maxWidth: 255 }}
            alt={valProps[0].text}
            src={valProps[0].img}
          />
        </RybotImageWrapper>
      </ValPropWrapper>
      <ValPropWrapper
        flexDirectionMobile="column"
        marginBottom="50px"
        marginTop="50px"
      >
        <div
          style={{
            display: 'flex',
            width: '50%',
            alignItems: 'center',
          }}
        >
          <Image
            style={{
              maxWidth: 600,
            }}
            alt={valProps[1].text}
            src={valProps[1].img}
          />
        </div>
        <div>
          <Header>
            Transform Your <CustomBr />
            Lessons with Tailored <CustomBr />
            Learning Resources
          </Header>
          <Description>
            Educators can request fully customized assets tailored to
            <CustomBr /> their classroom needs, including interactive videos,
            <CustomBr /> gamified modules, virtual labs, 3D experiences, AR{' '}
            <CustomBr />
            customizations, teacher resources, accessibility tools, and
            <CustomBr /> translations.
          </Description>
        </div>
      </ValPropWrapper>
      <ValPropWrapper
        marginBottom="0px"
        marginTop="50px"
        flexDirectionMobile="column-reverse"
      >
        <div>
          <Header>
            ryverse: The Ultimate <CustomBr />
            Destination for <CustomBr />
            Collaborative <CustomBr /> Education
          </Header>
          <Description>
            ryverse is a collaborative digital space where educators{' '}
            <CustomBr />
            partner with experts, project managers, and AI teaching
            <CustomBr /> assistants to streamline lesson planning, enhance{' '}
            <CustomBr />
            teaching strategies, and create tailored learning <CustomBr />
            experiences.
          </Description>
        </div>
        <div
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Desktop>
            <Image
              style={{
                maxWidth: 622,
              }}
              alt={valProps[2].text}
              src={valProps[2].img}
            />
          </Desktop>
          <TabletAndBelow>
            <Image
              style={{
                maxWidth: 622,
              }}
              alt={valProps[2].text}
              src={valProps[2].mobile}
            />
          </TabletAndBelow>
        </div>
      </ValPropWrapper>
    </Container>
  )
}

export default ValueProp
