import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles/colors'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
  border-radius: 40px 40px 0px 0px;

  ${up('lg')} {
    margin: 0 120px;
    padding: 58px;
  }
`

export const Header = styled.h1`
  color: ${colors.black};
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin: 0;
  margin-top: 22px;
  width: 368px;

  ${up('lg')} {
    font-size: 25px;
    width: 100%;
    white-space: nowrap;
  }
`

export const ByLine = styled.h2`
  color: ${colors.black};
  margin: 0;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 18px 0;

  ${up('lg')} {
    font-size: 22px;
  }
`

export const Quote = styled.p`
  color: ${colors.black};
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
  margin: 0;

  ${up('lg')} {
    font-size: 18px;
    height: 100px;
    margin-top: 0;
  }
`

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: none;

  height: 47px;
  width: 30px;

  border-radius: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  :last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    > svg {
      transform: rotate(180deg);
    }
  }

  ${up('lg')} {
    :hover {
      background-color: ${colors.rycoPurple};
      > svg {
        > g {
          > path {
            :last-of-type {
              stroke: ${colors.white};
            }
          }
        }
      }
    }
  }
`

export const ButtonWrapper = styled.div`
  display: none;

  ${up('lg')} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const MobileButtonWrapper = styled.div`
  > ${IconButton} {
    position: absolute;
    top: 50%;

    :nth-of-type(1) {
      left: 10px;
    }
    :nth-of-type(2) {
      right: 10px;
    }
  }

  ${up('lg')} {
    display: none;
  }
`

export const CirclesWrapper = styled.div`
  display: flex;
  grid-gap: 10px;
  margin-top: 20px;

  ${up('lg')} {
    position: absolute;
    bottom: 15px;
  }
`

export const Circle = styled.div<{ active: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? '#666666' : '#E5E5E5')};

  ${up('lg')} {
    width: 8px;
    height: 8px;
  }
`

// TODO: bellow this line is components being used elsewhere, lift them there or refactor

export const EmailInput = styled.input`
  border: 2px solid ${colors.black};
  background-color: ${colors.white};
  border-radius: 25px;
  color: ${colors.black};
  /* 4px is the border width */
  width: calc(310px - 4px);
  height: calc(55px - 4px);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  padding: 0px;
  margin-bottom: 8px;

  ::placeholder {
    color: ${colors.black};
    opacity: 0.5;
  }

  :focus {
    outline: none;
    border-color: ${colors.rycoPurple};
  }

  ${up('lg')} {
    width: calc(376px - 4px);
    height: calc(60px - 4px);
    margin-top: 46px;
    margin-bottom: 15px;
  }
`

export const Button = styled.button`
  border: 2px solid ${colors.black};
  background-color: ${colors.black};
  border-radius: 25px;
  color: ${colors.white};
  width: 310px;
  height: 55px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;

  ${up('lg')} {
    width: 376px;
    height: 60px;
    margin-bottom: 12px;
  }
`
