import React from 'react'

import { blog } from 'constants/links'
import { useBlog } from 'services'

import defaultBlogImage from './defaultBlogImage.png'
import {
  Wrapper,
  Header,
  SubHeader,
  BlogPostWrapper,
  BlogPostConatiner,
  BlogTitle,
  BlogExcerpt,
  BlogLink,
  BlogDate,
  Button,
  ScrollContainer,
  ImageBackground,
} from './styled-components'

const Blog = ({
  noTitle,
  options,
}: {
  noTitle?: boolean
  options?: { limit?: number; tagIds?: string[] }
}) => {
  const [titleHeight, setTitleHeight] = React.useState(0)
  const [imageHeight, setImageHeight] = React.useState(0)
  const [exerptHeight, setExerptHeight] = React.useState(0)

  const { posts } = useBlog({ limit: 15, ...options })

  if (!posts) return null

  const Post = ({
    formattedDate,
    url,
    image,
    title,
    excerpt,
  }: {
    formattedDate: string
    url: string
    image: string
    title: string
    excerpt: string
  }) => {
    const titleRef = React.useRef<HTMLHeadingElement>(null)
    const imageRef = React.useRef<HTMLImageElement>(null)
    const exerptRef = React.useRef<HTMLParagraphElement>(null)

    const [stateImage, setImage] = React.useState(
      `https://static.wixstatic.com/media${image?.split(
        'wix:image://v1'
      )[1]}`?.split('.jpg/')[0] + '.jpg'
    )

    React.useEffect(() => {
      if (titleRef.current) {
        titleRef.current.offsetHeight > titleHeight &&
          setTitleHeight(titleRef.current.offsetHeight)
      }
    }, [titleRef])

    React.useEffect(() => {
      if (imageRef.current) {
        imageRef.current.offsetHeight > imageHeight &&
          setImageHeight(imageRef.current.offsetHeight)
      }
    }, [imageRef])

    React.useEffect(() => {
      if (exerptRef.current) {
        exerptRef.current.offsetHeight > imageHeight &&
          setExerptHeight(exerptRef.current.offsetHeight)
      }
    }, [exerptRef])

    return (
      <BlogPostWrapper>
        <BlogLink
          target="_blank"
          href={url?.split('http://')[1]}
          rel="noreferrer"
        >
          <BlogPostConatiner>
            <ImageBackground>
              <img
                ref={imageRef}
                src={stateImage}
                onError={(e) => {
                  setImage(defaultBlogImage)
                }}
                alt="blog-post"
              />
            </ImageBackground>
            <BlogDate>{formattedDate}</BlogDate>
            {/* @ts-ignore-next-line */}
            <BlogTitle minHeight={titleHeight} ref={titleRef}>
              {title}
            </BlogTitle>
            <BlogExcerpt minHeight={exerptHeight} ref={exerptRef}>
              {excerpt}
            </BlogExcerpt>
          </BlogPostConatiner>
        </BlogLink>
      </BlogPostWrapper>
    )
  }

  return (
    <Wrapper id="blog">
      {/* <ScrollContainer> */}
      {posts.map((post, idx) => {
        const { title, excerpt, lastPublishedDate, url, media } = post

        const {
          //@ts-ignore-next-line
          wixMedia: { image },
        } = media

        const formattedDate = lastPublishedDate
          ? new Date(lastPublishedDate as Date).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
            })
          : ''

        return (
          <Post
            key={idx}
            formattedDate={formattedDate}
            url={url || blog}
            image={image}
            title={title as string}
            excerpt={excerpt as string}
          />
        )
      })}
      {/* </ScrollContainer> */}
      {/* <Button href={blog}>Visit our blog</Button> */}
    </Wrapper>
  )
}

export default Blog
