import React from 'react'

import leftFist from 'assets/images/aboutUs/leftFist.png'
import leftFistMobile from 'assets/images/aboutUs/leftFistMobile.png'
import rightFist from 'assets/images/aboutUs/rightFist.png'
import rightFistMobile from 'assets/images/aboutUs/rightFistMobile.png'
import { colors, Desktop, useBreakpoints } from 'styles'

import { useFistAnimationOnLoad } from './hooks'
import {
  Container,
  Header,
  SubHeader,
  Button,
  LeftFist,
  RightFist,
  CustomCircle as Circle,
  animationTiming,
  Spacer,
} from './styled-components'

const AboutUs = () => {
  const { isTabletOrBelow } = useBreakpoints()
  const leftFistRef = React.useRef<HTMLImageElement>(null)
  const rightFistRef = React.useRef<HTMLImageElement>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [animationFinished, setAnimationFinished] = React.useState(false)

  const customSetAnimationFinished = (state: boolean) => {
    setTimeout(() => {
      setAnimationFinished(state)
    }, animationTiming * 1000)
  }

  useFistAnimationOnLoad(
    leftFistRef,
    rightFistRef,
    containerRef,
    customSetAnimationFinished
  )

  const adjustForOffsetTop = (offset: number) =>
    offset + (isTabletOrBelow ? 225 : 100)
  return (
    <Container id="about" ref={containerRef}>
      <Header>
        ryco is here to bring{' '}
        <Desktop>
          <br />
        </Desktop>{' '}
        change to classrooms
      </Header>
      <SubHeader>
        We are here to empower educators with innovative AI-driven tools,
        developed in collaboration with experienced teachers and experts,
        helping save time, boost engagement, and bring tailored learning to each
        classroom.
      </SubHeader>

      <Spacer />

      <Circle
        animationFinished={animationFinished}
        size={isTabletOrBelow ? 144 : 365}
        top={adjustForOffsetTop(isTabletOrBelow ? -100 : -42)}
        left={isTabletOrBelow ? -90 : -81}
        color={isTabletOrBelow ? colors.burntOrange : colors.black}
      />
      <Circle
        animationFinished={animationFinished}
        size={isTabletOrBelow ? 32 : 51}
        top={adjustForOffsetTop(isTabletOrBelow ? -105 : 41)}
        left={isTabletOrBelow ? 50 : 285}
        color={isTabletOrBelow ? colors.burntOrange : colors.black}
      />

      <Desktop>
        <Circle
          animationFinished={animationFinished}
          size={332}
          top={adjustForOffsetTop(80)}
          right={-200}
          color={colors.yellow}
          asSquare
          angle={-14.599}
        />
        <Circle
          animationFinished={animationFinished}
          size={65}
          top={adjustForOffsetTop(-24)}
          right={118}
          color={colors.yellow}
        />
        <Circle
          animationFinished={animationFinished}
          size={276}
          top={adjustForOffsetTop(467)}
          left={93}
          color={colors.burntOrange}
        />
        <Circle
          animationFinished={animationFinished}
          size={48}
          top={adjustForOffsetTop(557)}
          left={26}
          color={colors.burntOrange}
        />
        <Circle
          animationFinished={animationFinished}
          size={56}
          top={adjustForOffsetTop(575)}
          right={49}
          color={colors.lightBlue}
        />
      </Desktop>

      <Circle
        animationFinished={animationFinished}
        size={isTabletOrBelow ? 70 : 136}
        top={adjustForOffsetTop(isTabletOrBelow ? 355 : 586)}
        color={colors.green}
      />

      <Circle
        animationFinished={animationFinished}
        size={isTabletOrBelow ? 91 : 121}
        top={adjustForOffsetTop(isTabletOrBelow ? 400 : 800)}
        left={isTabletOrBelow ? -22 : 90}
        color={colors.yellow}
        asSquare
        angle={35.161}
      />
      <Circle
        animationFinished={animationFinished}
        size={isTabletOrBelow ? 178 : 282}
        top={adjustForOffsetTop(isTabletOrBelow ? 191 : 643)}
        right={isTabletOrBelow ? -50 : -77}
        color={colors.lightBlue}
      />
      <LeftFist
        alt="fist left"
        ref={leftFistRef}
        src={isTabletOrBelow ? leftFistMobile : leftFist}
      />
      <RightFist
        alt="fist right"
        ref={rightFistRef}
        src={isTabletOrBelow ? rightFistMobile : rightFist}
      />
    </Container>
  )
}

export default AboutUs
