import { HashLink } from 'react-router-hash-link'

import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'

export const Wrapper = styled.div<{
  miniFooter?: boolean
  hideNewsLetter?: boolean
}>`
  display: flex;
  width: 100%;
  border-top: 1px solid ${colors.white};

  ${up('lg')} {
    padding: 70px 120px 34px 120px;
    width: calc(100% - 240px);
    border-top: none;
  }

  ${({ hideNewsLetter }) =>
    hideNewsLetter &&
    css`
      justify-content: center;
      align-items: center;
      flex-direction: column;
      ${up('lg')} {
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: row;
      }
    `}
`

export const LogoSocialWrapper = styled.div<{ hideNewsLetter?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 16px 0 18px 24px;
  width: 50%;
  align-self: flex-end;

  ${up('lg')} {
    margin: 0;
  }

  ${({ hideNewsLetter }) =>
    hideNewsLetter &&
    css`
      justify-content: center;
      align-items: center;
      align-self: center;
      margin-left: 0px;
      ${up('lg')} {
        justify-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;
      }
    `}
`

export const LogoLink = styled(HashLink)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${colors.white};
  text-decoration: none;
  width: fit-content;

  ${up('lg')} {
    font-size: 40px;
  }
`

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 17px;
  overflow: visible;

  ${up('lg')} {
    margin-top: 35px;
  }
`

export const SocialLinkIcon = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})<{ src: string; alt: string }>`
  text-decoration: none;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 25px;
  height: 25px;

  &:not(:last-child) {
    margin-right: 13px;
  }

  ${up('lg')} {
    width: 34px;
    height: 34px;
    &:not(:last-child) {
      margin-right: 18px;
    }
  }
`

export const CopyRight = styled.p<{ hideNewsLetter?: boolean }>`
  margin: 0;
  margin-top: 17px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${colors.white};
  white-space: nowrap;

  ${up('lg')} {
    margin-top: 35px;
    margin-bottom: 45px;
    font-size: 15px;
  }

  ${({ hideNewsLetter }) =>
    hideNewsLetter &&
    css`
      margin-top: 0;
      margin-bottom: 16px;
      ${up('lg')} {
        margin-bottom: 0 !important;
      }
    `}
`

export const Header = styled.h1`
  margin: 0;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 46px */
  color: ${colors.white};
  text-align: center;
  padding-top: 50px;

  ${up('lg')} {
    font-size: 40px;
    text-align: left;
    margin: 0;
    padding-bottom: 8px;
    padding-top: 0;
  }
`

export const SubHeader = styled.h2`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: ${colors.white};
  margin-bottom: 0px;
  text-align: center;

  ${up('lg')} {
    font-size: 18px;
    text-align: left;
    margin: 0;
  }
`

export const NewsLetterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`

export const ExplainerText = styled.p`
  color: ${colors.white};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin: 0;
  text-align: center;
  padding-bottom: 20px;

  ${up('lg')} {
    text-align: left;
    padding-bottom: 80px;
    margin-top: 80px;
  }
`

export const EmailInput = styled.input`
  padding: 10px 16px;

  background-color: ${colors.white};
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  ::placeholder {
    overflow: visible;
  }

  height: 15px;

  border: 2px solid #cccccc;
  border-radius: 5px;

  align-self: center;
  width: calc(75% - 20px - 16px - 4px);

  max-width: calc(400px - 20px - 16px - 4px);

  ${up('lg')} {
    margin: 0;
    width: 75%;
    max-width: unset;
  }
`

export const SubmitButton = styled.button`
  background-color: ${colors.rycoPurple};
  color: ${colors.white};
  border-radius: 100px;
  height: 39px;

  align-items: center;
  display: flex;
  border: none;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  width: 75%;
  align-self: center;
  justify-content: center;
  margin-top: 20px;
  padding: 15px 18px;
  max-width: 400px;

  ${up('lg')} {
    margin-top: 0;
    width: 25%;
    margin-left: 30px;
    max-width: unset;
  }
`

export const InputContainer = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  ${up('lg')} {
    flex-direction: row;
  }
`

export const BackgroundWrapper = styled.div``
