import React, { useEffect, useRef } from 'react'

import { colors as colorsObj } from 'styles'

const colors = Object.values(colorsObj)

const MouseTrail: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const particles: any[] = []

  useEffect(() => {
    const canvas = canvasRef.current!
    const ctx = canvas.getContext('2d')!
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    })

    const mouse = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    }

    window.addEventListener('mousemove', (event) => {
      mouse.x = event.clientX
      mouse.y = event.clientY
      for (let i = 0; i < 5; i++) {
        particles.push(new Particle(mouse.x, mouse.y))
      }
    })

    class Particle {
      x: number
      y: number
      size: number
      speedX: number
      speedY: number
      color: string

      constructor(x: number, y: number) {
        this.x = x
        this.y = y
        this.size = Math.random() * 6 + 2
        this.speedX = (Math.random() - 0.5) * 2
        this.speedY = (Math.random() - 0.5) * 2
        this.color = colors[Math.floor(Math.random() * colors.length)] // Randomly pick from brand colors
      }

      update() {
        this.x += this.speedX
        this.y += this.speedY
        this.size *= 0.95 // Shrinks over time
      }

      draw() {
        ctx.fillStyle = this.color
        ctx.beginPath()
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2)
        ctx.fill()
      }
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      for (let i = 0; i < particles.length; i++) {
        particles[i].update()
        particles[i].draw()

        if (particles[i].size < 0.5) {
          particles.splice(i, 1)
          i--
        }
      }

      requestAnimationFrame(animate)
    }

    animate()
  }, [])

  return (
    <canvas
      ref={canvasRef}
      style={{ position: 'fixed', top: 0, left: 0, zIndex: -1 }}
    />
  )
}

export default MouseTrail
