import customMarker from 'assets/images/sassTable/customMarker.svg'
import { Header as DefaultHeader } from 'pages/Home/styled-components'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles/colors'

export const Header = styled.h1`
  margin: 100px 30px 50px 30px;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  color: ${colors.white};
  z-index: 1;
  text-align: center;

  ${up('md')} {
    font-size: 65px;
  }

  ${up('lg')} {
    font-size: 80px;
    margin: 137px 0px 80px 0px;
  }
`

export const SubHeader = styled.h2`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${colors.white};
  text-align: center;
  margin: 0 30px 190px 30px;
  z-index: 1;

  ${up('lg')} {
    font-size: 20px;
  }
`

export const Container = styled.div`
  background-color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`

export const SassTableContainer = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SassTableHeader = styled(DefaultHeader)`
  font-size: 30px;
  text-align: center;
  margin: 0;
  padding-top: 99px;
  padding-bottom: 40px;
  color: ${colors.black};

  ${up('lg')} {
    font-size: 60px;
  }
`

export const Link = styled.a.attrs({ target: '_blank' })`
  color: ${colors.rycoPurple};
  font-family: Poppins;
  font-size: 22px;
  text-decoration: none;
  margin-bottom: 70px;
  font-weight: 500;
`

export const TableWrapper = styled.div`
  display: flex;
  gap: 33px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 33px 70px 33px;
`

export const SassTitle = styled.h3`
  font-size: 26px;
  margin: 0;
  font-weight: 400;
  margin-bottom: 15px;
  white-space: nowrap;
`
export const SassPrice = styled.p`
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  margin: 0;
  margin-bottom: 20px;
`
export const SassLabel = styled.p`
  margin: 0;
  margin-bottom: 30px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`
export const SassDescription = styled.p`
  margin: 0;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  height: 170px;
`

export const SassItem = styled.li`
  list-style-image: url(${customMarker});
  margin-left: 4px;
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0;
  }
`

export const BlankSassItem = styled.li`
  list-style: none;
  color: ${colors.grayDark3};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`

export const BlankWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 100%;
`

export const FeaturesWrapper = styled.div<{ color: string; isCustom: boolean }>`
  border: 4px solid ${({ color }) => color};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: ${({ isCustom }) => !isCustom && '100%'};
`

export const LinkButton = styled.a.attrs({ target: '_blank' })`
  background-color: ${colors.rycoPurple};
  color: ${colors.white};
  text-decoration: none;
  text-align: center;
  padding: 10px 12px;
  width: 70%;
  font-size: 14px;
  border-radius: 100px;
  margin-top: 40px;
  font-weight: 500;
  align-self: center;
`
