import React from 'react'

import { colors } from 'styles'
import { useBreakpoints } from 'styles'

import Blob from './Blob'
import { StyleWrapper, Circle } from './styled-components'

// Define a type for the Circle configuration
type CircleConfig = {
  color: string
  size: [number, number]
  right?: [number, number]
  top?: [number, number]
  left?: [number, number]
  bottom?: [number, number]
}

const CircleConfigurations: CircleConfig[] = [
  {
    color: colors.burntOrange,
    size: [363, 692],
    right: [-177, -158],
    top: [-105, -67],
  },
  {
    color: colors.burntOrange,
    size: [19, 20],
    right: [190, 550],
    top: [49, 183],
  },
  { color: colors.black, size: [247, 470], left: [-91, -152], top: [11, -77] },
  { color: colors.black, size: [29, 39], left: [161, 400], top: [147, 99] },
  {
    color: colors.yellow,
    size: [191, 364],
    left: [34, 35],
    bottom: [-20, 15],
  },
  // {
  //   color: colors.yellow,
  //   size: [25, 32],
  //   left: [110, 203],
  //   bottom: [240, 416],
  // },
  {
    color: colors.black,
    size: [74, 141],
    right: [50, 180],
    bottom: [25, 100],
  },
  // {
  //   color: colors.black,
  //   size: [18, 35],
  //   right: [55, 84],
  //   bottom: [150, 300],
  // },

  // {
  //   color: colors.lightBlue,
  //   size: [0, 222],
  //   right: [0, 550],
  //   bottom: [0, 300],
  // },
  // {
  //   color: colors.lightBlue,
  //   size: [0, 25],
  //   right: [0, 450],
  //   bottom: [0, 260],
  // },
]

const BackgroundOverlay: React.FC = () => {
  const { isTabletOrBelow } = useBreakpoints()

  return (
    <StyleWrapper>
      {CircleConfigurations.map((config, index) => (
        <Circle
          size={isTabletOrBelow ? config.size[0] : config.size[1]}
          key={index}
          right={isTabletOrBelow ? config.right?.[0] : config.right?.[1]}
          top={isTabletOrBelow ? config.top?.[0] : config.top?.[1]}
          left={isTabletOrBelow ? config.left?.[0] : config.left?.[1]}
          bottom={isTabletOrBelow ? config.bottom?.[0] : config.bottom?.[1]}
          isTabletOrBelow={isTabletOrBelow}
          color={isTabletOrBelow ? config.color : 'transparent'}
        >
          {!isTabletOrBelow && (
            <Blob
              color={config.color}
              radius={isTabletOrBelow ? config.size[0] : config.size[1] / 2}
              markers={false}
              height={isTabletOrBelow ? config.size[0] : config.size[1] + 100}
            />
          )}
        </Circle>
      ))}
    </StyleWrapper>
  )
}

export default BackgroundOverlay
