import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { TabletAndBelow, Desktop } from 'styles'

import HamburgerMenu from './HamburgerMenu'
import {
  Wrapper,
  LogoLink,
  NavWrapper,
  Link,
  Button,
} from './styled-components'

const Header = () => {
  const { pathname } = useLocation()

  React.useEffect(() => {
    //scroll to top everytime the user navigates to a new page
    document.body.scrollTo({
      top: 0,
      behavior: 'auto',
    })
  }, [pathname])

  const [showScrollHeader, setShowScrollHeader] = React.useState(false)

  const element = document.body

  React.useEffect(() => {
    const checkScrollTop = () => {
      //if the user scrolls down 10px, show the background
      if (!showScrollHeader && element && element.scrollTop > 100) {
        setShowScrollHeader(true)
      } else if (showScrollHeader && element && element.scrollTop <= 100) {
        //if the user scrolls back up to the top, hide the background
        setShowScrollHeader(false)
      }
    }

    element?.addEventListener('scroll', checkScrollTop)
    return () => {
      element?.removeEventListener('scroll', checkScrollTop)
    }
  }, [showScrollHeader, element])

  if (
    pathname === '/terms' ||
    pathname === '/privacy' ||
    pathname === '/maintenance'
  ) {
    return null
  }

  const isHome = pathname === '/' || pathname === '/#top'

  const activePath = pathname.split('/')[1]

  return (
    <>
      {!isHome && <div style={{ height: 60 }} />}
      {isHome && !showScrollHeader ? (
        <Wrapper id="top">
          <LogoLink to="/#top">ryco.io</LogoLink>
          <TabletAndBelow>
            <HamburgerMenu showScrollHeader={showScrollHeader} />
          </TabletAndBelow>
          <Desktop>
            <NavWrapper>
              <Button
                to="https://www.app.ryco.io/auth/createAccount"
                target="_blank"
              >
                sign up
              </Button>
              <Link to="/services">services</Link>
              <Link to="/blog">blog</Link>
              <Link to="/about">about</Link>
              <Link to="/resourceHub">resources</Link>
              <Link target="_blank" to="https://www.app.ryco.io/auth/signIn">
                sign in
              </Link>
            </NavWrapper>
          </Desktop>
        </Wrapper>
      ) : (
        <Wrapper id="top" size="sm">
          <LogoLink size="sm" to="/#top">
            ryco.io
          </LogoLink>
          <TabletAndBelow>
            <HamburgerMenu showScrollHeader />
          </TabletAndBelow>
          <Desktop>
            <NavWrapper size="sm">
              <Link
                size="sm"
                to="/#top"
                isActive={activePath === '' || activePath === '#top'}
              >
                home
              </Link>
              <Link
                size="sm"
                to="/services"
                isActive={activePath === 'services'}
              >
                services
              </Link>
              <Link size="sm" to="/blog" isActive={activePath === 'blog'}>
                blog
              </Link>
              <Link size="sm" to="/about" isActive={activePath === 'about'}>
                about
              </Link>
              <Link
                size="sm"
                to="/resourceHub"
                isActive={activePath === 'resourceHub'}
              >
                resources
              </Link>
              <Link
                size="sm"
                target="_blank"
                to="https://www.app.ryco.io/auth/signIn"
              >
                sign in
              </Link>
              <Button
                size="sm"
                to="https://www.app.ryco.io/auth/createAccount"
                target="_blank"
              >
                sign up
              </Button>
            </NavWrapper>
          </Desktop>
        </Wrapper>
      )}
    </>
  )
}

export default Header
