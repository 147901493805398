import React from 'react'

import ScrollButton from 'components/ScrollToTopButton'
import { default as BlogPost } from 'pages/Home/Blog'
import { colors } from 'styles'

import { SubHeader, Header } from './styled-components'

export const CustomHeader = ({
  children,
  ...props
}: {
  children: React.ReactNode
  style?: React.CSSProperties
}) => <Header {...props}>{children}</Header>

export const CustomSubHeader = ({
  children,
  ...props
}: {
  children: React.ReactNode
  style?: React.CSSProperties
}) => <SubHeader {...props}>{children}</SubHeader>

const Blog = () => {
  return (
    <div
      style={{
        backgroundColor: colors.white,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ScrollButton />
      <CustomHeader>Our Blog</CustomHeader>
      <CustomSubHeader>
        Welcome to the ryco blog—your hub for edtech insights! Discover the
        latest trends, innovative teaching strategies, and new tools shaping
        education. Stay updated with behind-the-scenes news at ryco and discover
        what we're building to make learning more personalized and engaging.
        Check back often for fresh and valuable perspectives on the future of
        education. We've always got something worth reading!
      </CustomSubHeader>
      <div>
        <BlogPost />
      </div>
    </div>
  )
}

export default Blog
