//TODO: lift these to common components
import { Header as DefaultHeader } from 'pages/Home/styled-components'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;

  padding-bottom: 50px;

  ${up('lg')} {
    padding-bottom: 100px;
  }
`

export const Header = styled(DefaultHeader)`
  font-size: 32px;
  color: ${colors.black};
  margin: 0;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 50px;

  ${up('md')} {
    margin-left: 100px;
    margin-right: 100px;
  }

  ${up('lg')} {
    white-space: nowrap;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 100px;
    font-size: 40px;
  }
`

export const Description = styled.p`
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 20px;
  ${up('md')} {
    margin-left: 100px;
    margin-right: 100px;
  }
  ${up('lg')} {
    margin-left: 100px;
    margin-right: 100px;
    white-space: nowrap;
    margin-top: 50px;
    font-size: 18px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
`

export const ValPropWrapper = styled.div<{
  marginTop?: string
  marginBottom?: string
  flexDirectionMobile?: string
}>`
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom?.split('px')[0] || 0 / 2 || +'px'};
  flex-direction: ${({ flexDirectionMobile }) => flexDirectionMobile};

  ${up('lg')} {
    flex-direction: row;
    margin-bottom: ${({ marginBottom }) => marginBottom};
  }
`

export const RybotImageWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  align-self: center;

  ${up('lg')} {
    align-self: flex-end;
  }
`
