import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles'

export const Header = styled.h1`
  font-family: Poppins;
  font-weight: 600;
  color: ${colors.white};
  z-index: 1;
  align-self: flex-start;
  color: ${colors.black};
  margin-left: 60px;
  margin-top: 60px;

  font-size: 40px;

  margin: 30px;

  ${up('lg')} {
    font-size: 50px;
    margin: 30px 60px 30px 60px;
  }
`

export const SubHeader = styled.h2`
  font-family: Poppins;
  font-style: normal;
  z-index: 1;
  color: ${colors.black};
  align-self: flex-start;
  font-weight: 400;
  max-width: 600px;
  font-size: 16px;
  margin: 0 30px 20px 30px;

  ${up('lg')} {
    font-size: 20px;
    margin: 0 60px 20px 60px;
  }
`
