export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  pink: '#C74ABF',
  hotPink: '#F019B4',
  magenta: '#F01966',
  rycoPurple: '#C205FF',
  darkPurple: '#9301C2',
  purple: '#5F02A4',
  lightPurpleHover: '#DC7AFC',
  yellow: '#F0CE19',
  orange: '#F06619',
  burntOrange: '#F04D19',
  green: '#27CE7E',
  lightBlue: '#19BCF0',
  blue: '#0596FF',
  lightGreen: '#46CE9A',
  grayDark1: '#333',
  grayDark3: '#888',
}
