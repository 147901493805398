import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useFistAnimationOnLoad = (
  leftFistRef: React.RefObject<HTMLImageElement>,
  rightFistRef: React.RefObject<HTMLImageElement>,
  containerRef: React.RefObject<HTMLDivElement>,
  setAnimationFinished: (bool: boolean) => void
) => {
  const location = useLocation()

  useEffect(() => {
    const triggerAnimation = () => {
      if (containerRef.current) {
        leftFistRef.current?.classList.remove('animateOut')
        rightFistRef.current?.classList.remove('animateOut')
        leftFistRef.current?.classList.add('animateIn')
        rightFistRef.current?.classList.add('animateIn')

        setAnimationFinished(true)
      }
    }

    // Trigger animation on route change
    triggerAnimation()
  }, [
    location.pathname,
    leftFistRef,
    rightFistRef,
    containerRef,
    setAnimationFinished,
  ])
}
