import { HashLink } from 'react-router-hash-link'

import styled from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const NavContainer = styled.nav`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 100px);

  > * {
    :not(:last-child) {
      margin-bottom: 30px;
    }
  }
`

export const NavItem = styled(HashLink)`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 25px;
  line-height: 110%;
  font-style: normal;
  text-decoration: none;
  color: ${colors.white};
`

export const SocialsFooter = styled.div`
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const SocialIcon = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})<{ src: string; alt: string }>`
  text-decoration: none;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 40px;
  height: 40px;

  &:not(:last-child) {
    margin-right: 30px;
  }
`
