import { CustomSubHeader } from 'pages/Blog/Blog'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CustomListItem = styled(CustomSubHeader)`
  margin: 0;
  max-width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`

export const CustomListItemLabel = styled(CustomListItem)`
  font-weight: 700 !important;
  margin-top: 20px;
  font-size: 24px;
  ${up('lg')} {
    font-size: 28px;
    margin-top: 40px;
  }
`

export const CustomItem = styled.div`
  width: 100%;
  ${up('lg')} {
    width: 50%;
  }
`

export const CustomDiv = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;

  ${up('lg')} {
    margin-top: 90px;
  }
`

export const Container = styled.div`
  width: 100%;
  padding-bottom: 30px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;

  ${up('lg')} {
    padding-bottom: 60px;
  }
`

export const CustomExample = styled.div`
  width: 100%;
  ${up('lg')} {
    width: 50%;
  }
`

export const CustomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  margin: 20px 50px;

  ${up('lg')} {
    gap: 100px;
    margin: 40px 150px;
    flex-direction: row;
  }
`

export const SampleModalButton = styled.button`
  color: ${colors.blue};
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins';
  padding: 0;
  margin: 0;
  margin-bottom: 32px;
  cursor: pointer;

  ${up('lg')} {
    font-size: 20px;
    margin-bottom: 0;
  }
`

export const CustomImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;

  ${up('lg')} {
    min-height: 400px;
  }
`

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 18px;
  background-color: ${colors.white};
  width: 100%;

  ${up('lg')} {
    width: 75%;
  }
`

export const ModalTitle = styled(CustomListItemLabel)`
  margin: 0;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`
