import React from 'react'

import JoinTheTeam from 'components/JoinTheTeam'
import ScrollButton from 'components/ScrollToTopButton'
import Testimonials from 'components/Testimonials'
import { CustomHeader } from 'pages/Blog/Blog'
import AboutUs from 'pages/Home/AboutUs'
import Customers from 'pages/Home/Customers'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { colors } from 'styles'
import { useBreakpoints } from 'styles'

import alex from './TeamMemberHeadshots/alex.png'
import cait from './TeamMemberHeadshots/cait.png'
import crystal from './TeamMemberHeadshots/crystal.png'
import dan from './TeamMemberHeadshots/dan.png'
import hannah from './TeamMemberHeadshots/hannah.png'
import heather from './TeamMemberHeadshots/heather.png'
import jenna from './TeamMemberHeadshots/jenna.png'
import jess from './TeamMemberHeadshots/jess.png'
import linh from './TeamMemberHeadshots/linh.png'
import lissie from './TeamMemberHeadshots/lissie.png'
import matt from './TeamMemberHeadshots/matt.png'
import michael from './TeamMemberHeadshots/michael.png'
import riley from './TeamMemberHeadshots/riley.png'
import rukhsar from './TeamMemberHeadshots/ruk.png'
import rybot from './TeamMemberHeadshots/rybot.png'
import thi from './TeamMemberHeadshots/thi.png'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 50px 20px;
  padding: 20px;
  margin-bottom: 100px;

  ${up('md')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${up('lg')} {
    gap: 100px;
    grid-template-columns: repeat(3, 1fr);
  }
`

const About = () => {
  const { isTabletOrBelow } = useBreakpoints()

  const teamMembers = [
    //1
    {
      name: 'Riley Walker',
      title: 'Founder & CEO',
      image: riley,
      linkedIn: 'https://www.linkedin.com/in/riley-walker/',
    },
    {
      name: 'Dan Casey',
      title: 'VP of Product Development',
      image: dan,
      linkedIn: 'https://www.linkedin.com/in/dan-casey-/',
    },
    {
      name: 'Caitlyn Braunsdorf',
      title: 'VP of Curriculum Development',
      image: cait,
      linkedIn: 'https://www.linkedin.com/in/caitlyn-braunsdorf/',
    },
    {
      name: 'Matt Mullen',
      title: 'VP of Engineering',
      image: matt,
      linkedIn: 'https://www.linkedin.com/in/mattmull/',
    },
    {
      name: 'Alexander Hubenthal',
      title: 'VP of Finance',
      image: alex,
      linkedIn: 'https://www.linkedin.com/in/ahubenthal/',
    },
    //2
    {
      name: 'Lisse Zimmerman',
      title: 'Project Manager',
      image: lissie,
      linkedIn: 'https://www.linkedin.com/in/lissiezimmerman/',
    },
    {
      name: 'Rukhsar Sayani',
      title: 'Assistant Project Manager',
      image: rukhsar,
      linkedIn: 'https://www.linkedin.com/in/rukhsar-sayani/',
    },
    {
      name: 'Jessica Trafford',
      title: 'Lead Instructional Designer',
      image: jess,
      linkedIn: 'https://www.linkedin.com/in/jessica-trafford-350215157/',
    },
    {
      name: 'Heather Nieuwstad',
      title: 'Lead Instructional Designer',
      image: heather,
      linkedIn:
        'https://www.linkedin.com/in/heather-nieuwstad-worsfold-1653b8143/',
    },

    {
      name: 'Crystal Hartwig',
      title: 'Instructional Designer',
      image: crystal,
      linkedIn: 'https://www.linkedin.com/in/crystal-hartwig-2875961a2/',
    },
    //3
    {
      name: 'Hannah Williams',
      title: 'Sales & Marketing Coordinator',
      image: hannah,
      linkedIn: 'https://www.linkedin.com/in/hannah-m-williams/',
    },
    {
      name: 'Thitikarn (Thi) Phayoongsin',
      title: 'Multimedia Specialist',
      image: thi,
      linkedIn: 'https://www.linkedin.com/in/thi-phayoongsin/',
    },
    {
      name: 'Michael Eadie',
      title: 'Graphic Designer',
      image: michael,
      linkedIn: 'https://www.linkedin.com/in/meadie95/',
    },
    {
      name: 'Linh Ha',
      title: 'Software Engineering Intern',
      image: linh,
      linkedIn: 'https://www.linkedin.com/in/liam-ha/',
    },
    {
      name: 'Jenna Vaske',
      title: 'Social Media Outreach Intern',
      image: jenna,
      linkedIn: 'https://www.linkedin.com/in/jenna-vaske/',
    },

    !isTabletOrBelow
      ? {
          name: '',
          title: '',
          image: null,
          linkedIn: '',
        }
      : {
          name: 'rybot',
          title: 'AI Teaching Assistant',
          image: rybot,
          linkedIn: 'https://www.app.ryco.io/chat',
        },
  ]

  if (!isTabletOrBelow) {
    teamMembers.push({
      name: 'rybot',
      title: 'AI Teaching Assistant',
      image: rybot,
      linkedIn: 'https://www.app.ryco.io/chat',
    })
  }
  return (
    <div>
      <ScrollButton />
      <AboutUs />
      <div
        style={{
          backgroundColor: colors.white,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '60px',
          flexDirection: 'column',
        }}
      >
        <CustomHeader
          style={{
            textAlign: 'center',
            alignSelf: 'center',
            marginBottom: isTabletOrBelow ? '50px' : '100px',
          }}
        >
          Meet the team
        </CustomHeader>
        <Grid>
          {teamMembers.map((member, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              {member.image && (
                <a
                  href={member.linkedIn}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', display: 'flex' }}
                >
                  <img
                    style={{
                      width: '150px',
                      height: '150px',
                      borderRadius: '50%',
                      objectFit: 'contain',
                      border: `2px solid ${colors.rycoPurple}`,
                    }}
                    src={member.image}
                    alt={member.name}
                  />
                </a>
              )}
              <p
                style={{
                  color: colors.black,
                  fontWeight: 'bold',
                  fontSize: '20px',
                  margin: '15px 0 0',
                }}
              >
                {member.name}
              </p>
              <p
                style={{
                  color: colors.black,
                  fontSize: '16px',
                  margin: '5px 0 0',
                }}
              >
                {member.title}
              </p>
            </div>
          ))}
        </Grid>
      </div>
      <Customers all />
      <Testimonials />
      <JoinTheTeam />
    </div>
  )
}

export default About
