import * as React from 'react'
import { toast } from 'react-hot-toast'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'

import arrowDown from 'assets/images/icons/arrowDown.png'
import { CustomHeader, CustomSubHeader } from 'pages/Blog/Blog'
import {
  Wrapper, // Header,
  TextWrapper, // SubHeader,
  Icon,
} from 'pages/Done/styled-components'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { Desktop, TabletAndBelow, colors } from 'styles'

import highFive from './highFive.png'

const Container = styled.div`
  background-color: ${colors.white};
  display: flex;
  width: 100%;
  min-height: calc(100vh - 60px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${up('lg')} {
    min-height: calc(100vh - 229px - 60px);

    flex-direction: row;
    justify-content: space-evenly;

    > img {
      align-self: flex-end;
    }
  }
`

const EmailInput = styled.input`
  padding: 10px 16px;

  background-color: ${colors.white};
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  ::placeholder {
    overflow: visible;
  }

  height: 15px;

  border: 2px solid #cccccc;
  border-radius: 5px;
  width: 100%;
  align-self: center;
  width: 100%;
  ${up('lg')} {
    margin: 0;
  }
`

const SubmitButton = styled.button`
  background-color: ${colors.rycoPurple};
  color: ${colors.white};
  border-radius: 100px;
  height: 39px;

  align-items: center;
  display: flex;
  border: none;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  width: 60px;
  align-self: center;
  justify-content: center;
  margin-top: 20px;
  padding: 15px 18px;
`

const InputContainer = styled.div`
  margin: 20px 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 30px;
  max-width: 320px;

  ${up('lg')} {
    margin: 30px 60px;
  }
`

const NewsLetter = () => {
  const { setPathPageView } = useTrackingCode()

  React.useEffect(() => {
    setPathPageView(window.location.pathname)
  }, [setPathPageView])

  const [email, setEmail] = React.useState('')

  const onSubmit = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (!email || !email.includes('@')) {
      toast.error('Please enter a valid email address!')
      return
    }

    const baseUrl = 'https://api.hsforms.com/submissions/v3/integration/submit'
    const portalId = '22800304'
    const formId = '1dde2736-42a9-4f42-ad52-3ca1ddc22dcf'

    const requestUrl = `${baseUrl}/${portalId}/${formId}`

    const body = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: window.location.pathname,
      },
    }
    try {
      await fetch(requestUrl, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      toast.success('Email submitted successfully!')
      window.location.href = '/'
    } catch (error) {
      toast.error('Something went wrong, please try again later!')
    }
  }

  return (
    <Container>
      <TextWrapper>
        <CustomHeader style={{ lineHeight: '110%' }}>
          Join our newsletter
        </CustomHeader>

        <CustomSubHeader>
          Goodies sent directly to your mailbox. We promise we won't spam you!
        </CustomSubHeader>

        <InputContainer>
          <EmailInput
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <SubmitButton onClick={onSubmit}>Join</SubmitButton>
        </InputContainer>
      </TextWrapper>
      <img
        style={{
          maxHeight: '485px',
          maxWidth: '610px',
          marginBottom: '80px',
        }}
        src={highFive}
        alt="two people highfiving, one is in a purple sweatshirt with blue pants and is male, the other is a female in orange overalls"
      />
    </Container>
  )
}

export default NewsLetter
